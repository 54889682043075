<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          :to="{ name: 'module-arnprior-pool-arnprior-pool-bookings' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Bookings</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ pluralString(booking.order.customer.full_name) }} Pool Booking
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.bookingDialog.open(booking)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Booking</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Booking Info</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th class="text-right">Paid</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in booking.order.payments" :key="payment.id">
                  <td v-html="formatDateTime(payment.created_at)" />
                  <td v-html="ucfirst(payment.type)" />
                  <td v-html="'&#163;' + payment.amount" class="text-right" />
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card>
            <v-toolbar flat dark dense color="accent">Basic Details</v-toolbar>
            <div class="spacer"></div>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ booking.order.customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>
                    {{ booking.order.billing_address.line_1 }}<br />
                    {{ booking.order.billing_address.line_2 }}
                    {{ booking.order.billing_address.city }}<br />
                    {{ booking.order.billing_address.postcode }}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ booking.order.customer.email }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Glamping Pod</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-arnprior-customers-individual',
                        params: { customerId: booking.order.customer_id },
                      }"
                      >{{ booking.order.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip label small color="success">Paid</v-chip>
                  </td>
                </tr>
                <tr>
                  <th>Sub Total</th>
                  <td>£{{ booking.order.sub_total }}</td>
                </tr>
                <tr>
                  <th>VAT Total</th>
                  <td>£{{ booking.order.vat_total }}</td>
                </tr>
                <tr class="grey lighten-5">
                  <th>Total</th>
                  <td>£{{ booking.order.total }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Swim</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="bookings"
              class="mt-4"
              no-data-text="No Swimming Bookings found"
            >
            </v-data-table>
          </v-card>
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Swim</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="swimTableHeaders"
              :items="swim"
              class="mt-4"
              no-data-text="No Swimming Bookings found"
            >
            </v-data-table>
          </v-card>
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Extras</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Extras</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="extrasHeaders"
              :items="booking.extras"
              class="mt-4"
              no-data-text="No extras found"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Order</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Party Pod</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-arnprior-customers-individual',
                        params: { customerId: booking.order.customer_id },
                      }"
                      >{{ booking.order.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip label small color="success">Paid</v-chip>
                  </td>
                </tr>
                <tr>
                  <th>Sub Total</th>
                  <td>£{{ booking.order.sub_total }}</td>
                </tr>
                <tr>
                  <th>VAT Total</th>
                  <td>£{{ booking.order.vat_total }}</td>
                </tr>
                <tr class="grey lighten-5">
                  <th>Total</th>
                  <td>£{{ booking.order.total }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <booking-dialog ref="bookingDialog" />
  </div>
</template>

<script>
import BookingDialog from "./components/BookingDialog";

export default {
  components: {
    BookingDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Bookings",
          disabled: false,
          to: { name: "module-arnprior-pods-arnprior-pod-bookings" },
          exact: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "order.customer.full_name" },
        { text: "Booking Time", value: "formatted_dates.start_date" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
        { text: "Type", value: "booking_type" },
        { text: "Adults", value: "total_adults" },
        { text: "Kids", value: "total_kids" },
        { text: "Infants", value: "total_infants" },
        { text: "Babies", value: "total_babies" },
      ],
      paymentTableHeaders: [
        { text: "Date", value: "created_at" },
        { text: "Type", value: "type" },
        { text: "Paid", value: "amount" },
      ],
      swimTableHeaders: [
        { text: "Date", value: "order.customer.full_name" },
        { text: "Booking Time", value: "formatted_dates.start_date" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
      ],
    };
  },

  computed: {
    bookings() {
      return this.$store.getters["arnprior/bookingsStore/allBookedSwims"];
    },

    booking() {
      return this.$store.getters["arnprior/bookingsStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.booking.order.customer.full_name,
      disabled: true,
    });
  },
};
</script>
